// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export default gql`
  mutation activitySignup($subscription: ActivitySubscription!) {
    activitySignup(subscription: $subscription) {
      id
    }
  }
`
