<script setup lang="ts">
import { computed } from 'vue'
import { useGeneralStore } from '@/stores/GeneralStore'
import { toShortName } from '@/utils/strings'
import { useAuthStore } from '@/stores/AuthStore'

const generalStore = useGeneralStore()
const authStore = useAuthStore()

const shortOnBehalfOfName = computed(() => {
  if (!authStore.auth.proxy) {
    return ''
  }

  const { proxy } = authStore.auth
  if (generalStore.breakpoint === 'desktop') {
    return proxy.fullName
  }

  return toShortName(proxy.fullName)
})
</script>

<template>
  <div
    v-if="shortOnBehalfOfName.length > 0"
    class="bg-darkOcean100 flex items-center gap-3 fixed bottom-navigationBottomHeight left-0 w-full p-16 z-20 rounded-none desktop:justify-between desktop:p-3 desktop:rounded-8 desktop:unset-position desktop:unset-width"
  >
    <fa-icon :icon="['fas', 'circle-info']" class="text-xl" />
    <div class="h100">
      {{
        $t('onboarding.powerOfAttorney.banner', [
          toShortName(shortOnBehalfOfName),
        ])
      }}
    </div>
  </div>
</template>
