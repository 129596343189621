<script setup lang="ts">
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const router = useRouter()

const handleClose = () => {
  authStore.logout()
  router.push('/login')
}
</script>

<template>
  <BaseDialog :open="authStore.auth.loginStep === 'inactive'">
    <div
      class="fixed top-0 left-0 bg-mango50 w-screen h-screen desktop:w-540 desktop:unset-position desktop:unset-height desktop:bg-neutral0"
    >
      <div
        class="flex flex-col justify-between h-screen desktop:block desktop:unset-height p-24 pt-28"
      >
        <div class="h-full flex flex-col justify-center">
          <div class="flex justify-center mb-20">
            <div
              class="bg-neutral0 drop-shadow-sm w-120 h-120 rounded-full flex justify-center items-center desktop:bg-darkOcean100 desktop:w-60 desktop:h-60"
            >
              <fa-icon
                :icon="['fas', 'triangle-exclamation']"
                class="text-3xl desktop:text-xl"
              />
            </div>
          </div>
          <div class="h700 text-center mb-3">
            {{ $t('onboarding.inactiveUser.title') }}
          </div>
          <div class="b300 text-center mb-24">
            {{ $t('onboarding.inactiveUser.description') }}
          </div>
        </div>

        <VerticalLayout>
          <ButtonComponent
            size="large"
            text="global.ok"
            full-width
            gradient
            @click="handleClose"
          />
        </VerticalLayout>
      </div>
    </div>
  </BaseDialog>
</template>
