// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const PRICE_FRAGMENT = gql`
  fragment price on EventPrice {
    price
    currency
    profileType
    description
  }
`
