<script setup lang="ts">
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { GlobalEvents } from '@/Enums'

const status = ref(false)
const title = ref<null | string>(null)
const description = ref<null | string>(null)

const handleClose = () => {
  title.value = null
  description.value = null
  status.value = false
}

const onMessage = (event: any) => {
  title.value = event.title
  description.value = event.description
  status.value = true
}

onMounted(() => {
  document.addEventListener(GlobalEvents.SimpleMessage, onMessage)
})

onBeforeUnmount(() => {
  document.removeEventListener(GlobalEvents.SimpleMessage, onMessage)
})
</script>

<script lang="ts">
import MessageEvent from '@/utils/extends/MessageEvent'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'

export const simpleMessage = (
  title: string | null,
  description: string | null
) => {
  document.dispatchEvent(
    new MessageEvent(GlobalEvents.SimpleMessage, title, description)
  )
}
</script>

<template>
  <BaseDialog :open="status" @onClose="$emit('close')">
    <div
      class="ml-20 mr-20 rounded-16 bg-neutral0 p-24 pt-40 desktop:rounded-16 desktop:w-480 desktop:unset-height z-100"
    >
      <div class="flex flex-col justify-center h-full">
        <div class="text-center h700 mb-3" v-if="title">{{ title }}</div>
        <div class="text-center b300 mb-24" v-if="description">
          {{ description }}
        </div>
        <ButtonComponent
          type="primary"
          text="global.ok"
          gradient
          full-width
          @click="handleClose"
        />
      </div>
    </div>
  </BaseDialog>
</template>
