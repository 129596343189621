<script setup lang="ts">
import ButtonComponent from './ButtonComponent.vue'

const handleClick = () => {
  window.location.reload()
}
</script>

<template>
  <div class="flex justify-center desktop:mb-5 desktop:pb-0">
    <div
      class="w-full px-16 desktop:w-[theme(spacing.contentLarge)] desktop:unset-px pt-56"
    >
      <div class="flex justify-center mb-3">
        <div
          class="bg-neutral0 rounded-full drop-shadow w-56 h-56 flex justify-center items-center"
        >
          <fa-icon :icon="['fas', 'triangle-exclamation']" class="text-3xl" />
        </div>
      </div>
      <div class="text-center h600 mb-2">
        {{ $t('global.loadingError.title') }}
      </div>
      <div class="text-center b300">
        {{ $t('global.loadingError.description') }}
      </div>
      <div class="flex justify-center mt-16">
        <ButtonComponent
          text="global.reload"
          gradient
          size="large"
          @click="handleClick"
        />
      </div>
    </div>
  </div>
</template>
