<script setup lang="ts">
import { ref, onErrorCaptured, computed, toRefs } from 'vue'
import BackendError from './BackendError.vue'

interface Props {
  error: boolean | null
}

const props = defineProps<Props>()
const { error } = toRefs(props)

const internalError = ref(false)
onErrorCaptured(() => {
  internalError.value = true
})

const anyError = computed(() => {
  return internalError.value || error.value
})
</script>

<template>
  <div>
    <BackendError v-if="anyError" />
    <slot v-if="!anyError"></slot>
  </div>
</template>
