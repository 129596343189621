export const toShortName = (fullName: string) => {
  return (fullName || '')
    .split(' ')
    .filter((item, index) => index < 2)
    .map((item, index) => {
      if (index === 0) {
        return item
      }
      return item.substring(0, 1) + '.'
    })
    .join(' ')
}

export const fromMarkdownToHTML = (markdown: string) => {
  // Convert bold: **text** to <strong>text</strong>
  markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')

  // Convert italic: *text* to <em>text</em>
  markdown = markdown.replace(/\*(.*?)\*/g, '<em>$1</em>')

  return markdown
}
