import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar,
  faTrashCan,
  faLocationDot,
  faMessageLines,
} from '@fortawesome/pro-light-svg-icons'

// LIGHT

library.add(faCalendar, faTrashCan, faLocationDot, faMessageLines)
