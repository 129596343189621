<script setup lang="ts">
import { useAuthStore } from '@/stores/AuthStore'
import AlertDialog from '@/components/dialogs/AlertDialog.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import { useRouter } from 'vue-router'
import CitizenUserNotFound from './CitizenUserNotFound.vue'
import ParentNoProxyFound from './ParentNoProxyFound.vue'
import RelativeNoProxyFound from './RelativeNoProxyFound.vue'
import ProxyCPRInputDialog from './ProxyCPRInputDialog.vue'
import ProxyCPRListDialog from './ProxyCPRListDialog.vue'
import InactiveUserDialog from './InactiveUserDialog.vue'

const authStore = useAuthStore()
const router = useRouter()

const handleNoProxyCloseClick = () => {
  authStore.logout()
  router.push('/login')
}
</script>

<template>
  <CitizenUserNotFound />
  <InactiveUserDialog />
  <ProxyCPRInputDialog
    v-if="authStore.auth.loginStep === 'relative:proxy_input'"
  />
  <ProxyCPRListDialog
    v-if="authStore.auth.loginStep === 'parent:proxy_select'"
  />
  <ParentNoProxyFound />
  <RelativeNoProxyFound />

  <AlertDialog
    :is-open="authStore.auth.loginStep === 'parent:no_rights'"
    type="warning"
    :title="$t('onboarding.noRightsAlert.title')"
    :description="$t('onboarding.noRightsAlert.description')"
  >
    <VerticalLayout>
      <ButtonComponent
        text="global.close"
        fullWidth
        gradient
        size="large"
        type="secondary"
        @click="handleNoProxyCloseClick"
      />
    </VerticalLayout>
  </AlertDialog>
</template>
