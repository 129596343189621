<script setup lang="ts">
import { useWizardStore } from '@/stores/WizardStore'

const wizardStore = useWizardStore()
</script>

<template>
  <div
    class="transition duration-300 h400 w-96 h-full flex items-center justify-end"
  >
    {{
      $t('global.stepWithRange', [
        wizardStore.currentStep + 1,
        wizardStore.steps.length,
      ])
    }}
  </div>
</template>
