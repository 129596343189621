export enum GlobalEvents {
  FetchStarted = 'fetch-started',
  FetchCompleted = 'fetch-completed',
  SimpleErrorMessage = 'simple-error-message',
  SimpleMessage = 'simple-message',
  Logout = 'logout',
}

export enum FileUploadTypes {
  OnlyPDF = '.pdf',
  OnlyImage = 'image/*',
  OnlyDocumentation = '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ImageAndDocumentations = '.odp,.ods,.odt,.doc,.dot,.png,.pdf,.ppt,.rtf,.xls,.xml,.gif,.bmp,.pps,.txt,.msg,.jpg,.jpeg,.docx,.dotx,.docm,.dotm,.xlsx,.xltx,.xlsm,.xltm,.xlsb,.pptx',
}
