<script setup lang="ts">
import { GlobalEvents } from '@/Enums'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import ButtonComponent from './ButtonComponent.vue'
import AlertDialog from '@/components/dialogs/AlertDialog.vue'

const { t } = useI18n()
const message = ref<null | IErrorMessage>(null)

const onMessage = (event: ErrorEvent) => {
  message.value = event.message
}

onMounted(() => {
  document.addEventListener(GlobalEvents.SimpleErrorMessage, (event) =>
    onMessage(event as ErrorEvent)
  )
})

onBeforeUnmount(() => {
  document.removeEventListener(GlobalEvents.SimpleErrorMessage, (event) =>
    onMessage(event as ErrorEvent)
  )
})

const description = computed(() => {
  if (message.value?.shouldBeTranslated) {
    return t(message.value.message)
  }

  return message.value?.message
})
</script>

<script lang="ts">
import ErrorEvent from '@/utils/extends/ErrorEvent'
import { type IErrorMessage } from '@/Interfaces'
import { useI18n } from 'vue-i18n'

export const simpleError = (message: IErrorMessage) =>
  document.dispatchEvent(
    new ErrorEvent(GlobalEvents.SimpleErrorMessage, message)
  )
</script>

<template>
  <AlertDialog
    v-if="message"
    :is-open="!!message"
    type="error"
    :title="message.title || $t('global.anErrorOccurred')"
    :description="description"
  >
    <div>
      <ButtonComponent
        type="secondary"
        size="medium"
        text="global.ok"
        full-width
        @click="message = null"
      />
    </div>
  </AlertDialog>
</template>
