<script setup lang="ts">
import type { BaseDialogProps } from '@/Interfaces'
import { until } from '@vueuse/core'
import { watch, ref, onMounted, onUnmounted, toRefs } from 'vue'

const props = withDefaults(defineProps<BaseDialogProps>(), {
  backdropType: 'gray',
})
const dialogRef = ref<HTMLDialogElement>()

const { backdropType } = toRefs(props)

const emit = defineEmits<{
  (e: 'onOpen'): void
  (e: 'onClose'): void
}>()

const checkScrollbar = () => {
  let anyOpen = false
  const dialogs = document.querySelectorAll('dialog')
  dialogs.forEach((dialog: HTMLDialogElement) => {
    if (dialog.open) {
      anyOpen = true
    }
  })

  if (anyOpen) {
    document.body.style.overflowY = 'hidden'
  } else {
    document.body.style.overflowY = 'auto'
  }
}

const toggleDialog = () => {
  if (!dialogRef.value) {
    throw new Error('Dialog reference is not valid')
  }

  if (props.open) {
    dialogRef.value.showModal()
    checkScrollbar()
    emit('onOpen')
  } else {
    dialogRef.value.close()
    checkScrollbar()
    emit('onClose')
  }
}

const handleCancelEvent = (event: any) => {
  event.preventDefault()
}

watch(() => props.open, toggleDialog)

onMounted(async () => {
  await until(dialogRef).toBeTruthy()
  dialogRef?.value?.addEventListener('cancel', handleCancelEvent)
  toggleDialog()
})

onUnmounted(() => {
  dialogRef?.value?.removeEventListener('cancel', handleCancelEvent)
  checkScrollbar()
})
</script>

<template>
  <dialog
    ref="dialogRef"
    :class="[
      'bg-neutral0 rounded-16 animate-in fade-in duration-500',
      `backdrop-${backdropType}`,
      backdropType === 'gray'
        ? 'backdrop:bg-neutral1000 backdrop:opacity-80'
        : '',
    ]"
  >
    <span class="sr-only" lang="da">{{ ariaLabel }}</span>
    <slot></slot>
  </dialog>
</template>
