<template>
  <div class="w-screen h-screen flex justify-center items-center">
    <div class="flex flex-col justify-center items-center gap-20">
      <img
        src="@/assets/logo.png"
        width="330"
        height="88"
        :alt="$t('a11y.logo')"
      />
    </div>
  </div>
</template>
