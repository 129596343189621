// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export default gql`
  mutation activityCancelSignup($subscription: ActivitySubscription!) {
    activityCancelSignup(subscription: $subscription) {
      id
    }
  }
`
