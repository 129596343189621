import type { SessionStorageNames } from '@/Types'

class SessionManager<Type> {
  private name: SessionStorageNames

  constructor(name: SessionStorageNames) {
    this.name = name
  }

  set(value: Type) {
    sessionStorage.setItem(this.name, JSON.stringify(value))
  }

  get(): Type | null {
    const data = sessionStorage.getItem(this.name)
    if (data) {
      return JSON.parse(data) as Type
    }
    return null
  }

  getWithDefault(defaultValue: Type): Type {
    const data = sessionStorage.getItem(this.name)
    if (data) {
      const parsed = JSON.parse(data) as Type
      return {
        ...defaultValue,
        ...parsed,
      }
    }
    return defaultValue
  }
}

export default SessionManager
