<script setup lang="ts">
import {
  defineAsyncComponent,
  computed,
  type AsyncComponentLoader,
  markRaw,
} from 'vue'
import WizardLoading from './WizardLoading.vue'
import { useWizardStore } from '@/stores/WizardStore'
import SpinnerComponent from '@/components/shared/SpinnerComponent.vue'

const wizardStore = useWizardStore()

const component = computed(() => {
  return wizardStore.steps[wizardStore.currentStep]
})
</script>

<script lang="ts">
// Since we use defineAsyncComponent, we should show a wizard loading incase of
// slow network connection.
export const step = (loader: AsyncComponentLoader) => {
  return markRaw(
    defineAsyncComponent({ loader, loadingComponent: WizardLoading })
  )
}
</script>

<template>
  <suspense timeout="80">
    <template #default>
      <component :is="component" />
    </template>
    <template #fallback>
      <div
        class="flex justify-center pt-5 bg-neutral50 fixed w-full h-full items-center left-0 top-[calc(0px+theme(spacing.wizardHeaderHeight))] -mt-wizardHeaderHeight"
      >
        <SpinnerComponent background="text-neutral100" />
      </div>
    </template>
  </suspense>
</template>
