<script setup lang="ts">
import { useGeneralStore } from '@/stores/GeneralStore'
import { onBeforeUnmount, onMounted } from 'vue'

const generalStore = useGeneralStore()

const logScroll = () => {
  generalStore.setScrollY(window.scrollY)
}

onMounted(() => {
  window.addEventListener('scroll', logScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', logScroll)
})
</script>

<template>
  <div class="w-screen h-[calc(100dvh-theme(spacing.navigationBottomHeight))]">
    <div
      class="py-0 pb-[calc(theme(spacing.navigationBottomHeight)+24px)] desktop:p-0 desktop:pt-[calc(theme(spacing.navigationBottomHeight))] relative"
    >
      <slot></slot>
    </div>
  </div>
</template>
