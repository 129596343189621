// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { ACTIVITY_FRAGMENT } from '../fragments/activities'

export default gql`
  ${ACTIVITY_FRAGMENT}
  query getActivitiesByRange(
    $filter: Filter!
    $dateFrom: DateTime!
    $dateTo: DateTime!
  ) {
    activity {
      getActivitiesByFilters(
        dateFrom: $dateFrom
        dateTo: $dateTo
        filter: $filter
      ) {
        ...activity
      }
    }
  }
`
