class MessageEvent extends Event {
  public title: string | null
  public description: string | null

  constructor(type: string, title: string | null, description: string | null) {
    super(type)
    this.title = title
    this.description = description
  }
}

export default MessageEvent
