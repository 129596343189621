import { defineStore } from 'pinia'

interface IModalState {
  showModal: boolean
  selectedModal: string
}

export const useModalStore = defineStore('modal', {
  state: (): IModalState => {
    return {
      showModal: false,
      selectedModal: '',
    }
  },
  actions: {
    setSelectedModal(modal: string) {
      this.selectedModal = modal
    },
    setShowModal(show: boolean) {
      this.showModal = show
    },
    close() {
      this.showModal = false
      this.selectedModal = ''
    },
  },
})
