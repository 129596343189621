<script setup lang="ts">
import type { FormDividerTypes } from '@/Types'

interface Props {
  type?: FormDividerTypes
  extra?: string
}

withDefaults(defineProps<Props>(), {
  type: 'small',
  extra: '',
})

const CLASS_MAP: Record<FormDividerTypes, string> = {
  small: 'mt-2 mb-2',
  medium: 'mt-12 mb-12',
  large: 'mt-16 mb-16',
}
</script>

<template>
  <div
    :class="['w-full bg-neutral300 block', CLASS_MAP[type], extra]"
    style="height: 1px"
  />
</template>
