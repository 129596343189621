// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { NEWS_FRAGMENT } from '../fragments/news'

export default gql`
  ${NEWS_FRAGMENT}
  query News($dateFrom: DateTime!, $dateTo: DateTime!, $filter: Filter!) {
    newsItem {
      getNewsByFilters(dateFrom: $dateFrom, dateTo: $dateTo, filter: $filter) {
        ...news
      }
    }
  }
`
