<script setup lang="ts">
import SpinnerComponent from '@/components/shared/SpinnerComponent.vue'
import { ref, watch } from 'vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'

interface Props {
  isFetching: boolean
  message?: string | null
}

const props = defineProps<Props>()
const show = ref(false)

watch(
  () => props.isFetching,
  (value) => {
    if (value) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout)
        show.value = props.isFetching
      }, 100)
    } else {
      show.value = false
    }
  }
)
</script>

<template>
  <BaseDialog :open="show">
    <div
      class="fixed top-0 left-0 w-full h-full z-60 flex items-center justify-center"
    >
      <div class="bg-neutral0 rounded-16 p-40">
        <div class="flex justify-center">
          <SpinnerComponent background="text-neutral0" />
        </div>
        <div v-if="message" class="text-center mt-20">{{ message }}</div>
      </div>
    </div>
  </BaseDialog>
</template>
