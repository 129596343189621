import { defineStore } from 'pinia'
import type { Component } from 'vue'

interface IStep {
  title: String
}

interface InitOptions {
  currentStep: number
  steps: Component[]
  showStepIndicator: boolean
  onSuccess?: (() => void) | null
}

export interface IPromptOnCloseData {
  status: boolean
  titleKey: string
  descriptionKey: string
  onCloseFunction: () => void
}

export interface PromptParams {
  status: boolean
  titleKey: string
  descriptionKey: string
}

interface IWizardStoreState extends InitOptions {
  show: boolean
  promptOnClose: IPromptOnCloseData
}

export const useWizardStore = defineStore('wizard', {
  state: () => {
    return {
      show: false,
      currentStep: 0,
      promptOnClose: {
        status: false,
        titleKey: 'courses.exitWithoutBookingTitle',
        descriptionKey: 'courses.exitWithoutBookingDescription',
        onCloseFunction: () => {},
      },
      steps: [
        {
          title: '',
        } as IStep,
      ],
      showStepIndicator: true,
      onSuccess: null,
    } as IWizardStoreState
  },
  actions: {
    init(options: InitOptions) {
      this.show = true
      this.steps = options.steps
      this.currentStep = options.currentStep
      this.showStepIndicator = options.showStepIndicator
      this.onSuccess = options?.onSuccess || null
    },
    stop() {
      this.show = false
    },
    next() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep = this.currentStep + 1
      } else {
        this.stop()
        if (typeof this.onSuccess === 'function') {
          this.onSuccess()
        }
      }
    },
    back() {
      this.currentStep = this.currentStep - 1
    },
    setWarningPrompt(patch: PromptParams) {
      this.promptOnClose = {
        status: patch.status,
        titleKey: patch.titleKey,
        descriptionKey: patch.descriptionKey,
        onCloseFunction: () => {},
      }
    },
    disableWarningPrompt() {
      this.promptOnClose = {
        ...this.promptOnClose,
        status: false,
        titleKey: '',
        descriptionKey: '',
      }
    },
  },
})
