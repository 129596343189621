import type { ResponsiveBreaks } from '@/Types'
import { defineStore } from 'pinia'

interface IGeneralStore {
  scrollY: number
  innerWidth: number
  breakpoint: ResponsiveBreaks
  currentTitle: string | undefined
  animatedTitle: string | undefined
  isScrolled: boolean
  isScrolledOnMobile: boolean
  hasFooter: boolean
  hasStickyHeaderSlot: boolean
}

export const useGeneralStore = defineStore('general', {
  state: () => {
    return {
      scrollY: 0,
      innerWidth: 1280,
      breakpoint: 'desktop',
      currentTitle: undefined,
      animatedTitle: undefined,
      isScrolled: false,
      isScrolledOnMobile: false,
      hasFooter: false,
      hasStickyHeaderSlot: false,
    } as IGeneralStore
  },
  actions: {
    setScrollY(scrollY: number) {
      this.scrollY = scrollY
      this.calculateScrollOnMobile()
      this.calculateAnimatedTitle()
    },
    setCurrentTitle(title: string) {
      this.currentTitle = title
      this.calculateScrollOnMobile()
      this.calculateAnimatedTitle()
    },
    setInnerWidth(value: number) {
      this.innerWidth = value

      if (value < 1025) {
        this.breakpoint = 'mobile'
      } else {
        this.breakpoint = 'desktop'
      }
      this.calculateScrollOnMobile()
      this.calculateAnimatedTitle()
    },
    setHasFooter(value: boolean) {
      this.hasFooter = value
    },
    setStickyHeaderSlot(value: boolean) {
      this.hasStickyHeaderSlot = value
    },
    calculateScrollOnMobile() {
      this.isScrolled = this.scrollY > 20
      this.isScrolledOnMobile =
        this.scrollY > 20 && this.breakpoint !== 'desktop'
    },
    calculateAnimatedTitle() {
      if (this.breakpoint !== 'desktop') {
        this.animatedTitle = this.isScrolledOnMobile
          ? this.currentTitle
          : undefined
      } else {
        this.animatedTitle = ''
      }
    },
  },
})
