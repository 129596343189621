<script setup lang="ts">
import type { AlertTypes } from '@/Types'

interface Props {
  title: string
  type?: AlertTypes
  description?: string
}

interface ITypeTemplate {
  bgColor: string
  color: string
  icon: string[]
}

withDefaults(defineProps<Props>(), {
  type: 'success',
  descriptionParams: undefined,
})

// Color and icon themes by the alert type
const THEMES: Record<AlertTypes, ITypeTemplate> = {
  success: {
    bgColor: 'bg-green400',
    color: '',
    icon: ['fas', 'check'],
  },
  error: {
    bgColor: 'bg-red400',
    color: '',
    icon: ['fas', 'xmark'],
  },
  warning: {
    bgColor: 'bg-darkOcean100',
    color: 'text-neutral1000',
    icon: ['fas', 'triangle-exclamation'],
  },
}
</script>

<template>
  <div class="w-full flex justify-center py-5">
    <div class="w-full desktop:w-480">
      <div class="flex justify-center mb-5">
        <div
          :class="[
            'h-96 w-96 rounded-full flex justify-center items-center',
            THEMES[type].bgColor,
            ,
          ]"
        >
          <fa-icon
            :class="['w-14 h-14 text-neutral0', THEMES[type].color]"
            :icon="THEMES[type].icon"
          />
        </div>
      </div>
      <div aria-live="assertive" role="alert" class="sr-only">
        {{ title }} {{ description && `. ${description}` }}
      </div>

      <div
        class="text-center mb-2 text-2xl font-bold text-neutral1000 leading-7"
      >
        {{ title }}
      </div>
      <div v-if="description" class="text-center text-base font-normal mb-24">
        {{ description }}
      </div>

      <div class="flex justify-center">
        <div class="w-full flex flex-col gap-3">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
