<script setup lang="ts">
import MainHeaderComponent from './components/header/MainHeaderComponent.vue'
import GeneralContainer from './components/layout/GeneralContainer.vue'
import { onBeforeMount, onMounted, defineAsyncComponent } from 'vue'
import { useWizardStore } from './stores/WizardStore'
import { useGeneralStore } from './stores/GeneralStore'
import ViewSpinner from './components/layout/ViewSpinner.vue'
import SessionTimeoutDialog from './components/dialogs/SessionTimeoutDialog.vue'
import { GlobalEvents } from './Enums'
import { useModalStore } from './stores/ModalStore'
import SplashScreen from './components/shared/SplashScreen.vue'
import { useAuthStore } from './stores/AuthStore'
import { useRoute } from 'vue-router'
import WizardDialog from './components/dialogs/WizardDialog.vue'
import LoginStepsModals from './components/login/LoginStepsModals.vue'

const GlobalSpinner = defineAsyncComponent(
  () => import('@/components/layout/GlobalSpinner.vue')
)

const SimpleError = defineAsyncComponent(
  () => import('@/components/shared/SimpleError.vue')
)

const SimpleMessageDialog = defineAsyncComponent(
  () => import('@/components/dialogs/SimpleMessageDialog.vue')
)

const wizardStore = useWizardStore()
const modalStore = useModalStore()
const generalStore = useGeneralStore()
const authStore = useAuthStore()
const route = useRoute()

// We want to save every change to the sessionStorage
authStore.$subscribe(authStore.saveStorage)

const resizeHandler = () => {
  generalStore.setInnerWidth(document.body.offsetWidth)
}

const onLogout = () => {
  wizardStore.stop()
  modalStore.close()
}

onMounted(() => {
  resizeHandler()
  authStore.init()
  window.addEventListener('resize', resizeHandler)
  document.addEventListener(GlobalEvents.Logout, onLogout)
})

onBeforeMount(() => {
  window.removeEventListener('resize', resizeHandler)
})
</script>

<template>
  <SplashScreen v-if="authStore.auth.token && !authStore.ready" />
  <main v-if="authStore.ready" class="bg-mango50">
    <MainHeaderComponent
      v-if="authStore.auth.loginStep !== 'none' && route.name !== 'login'"
    />
    <GeneralContainer>
      <router-view v-slot="{ Component }">
        <ViewSpinner :component="Component" />
      </router-view>
    </GeneralContainer>
    <SessionTimeoutDialog />
  </main>
  <SimpleMessageDialog />
  <SimpleError />
  <WizardDialog />
  <LoginStepsModals />
  <GlobalSpinner />
</template>
