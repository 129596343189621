// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { NEWS_FRAGMENT } from '../fragments/news'

export default gql`
  ${NEWS_FRAGMENT}
  query NewsEntry($id: ID!) {
    newsEntry(id: $id) {
      ...news
    }
  }
`
