// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { PICTURE_FRAGMENT } from './pictures'
import { PICTOGRAM_FRAGMENT } from './pictograms'
import { VIDEO_FRAGMENT } from './videos'
import { DOCUMENT_FRAGMENT } from './documents'
import { COMPACT_DEPARTMENT_FRAGMENT } from './departments'
import { COMPACT_INSTITUTION_FRAGMENT } from './institutions'

export const NEWS_FRAGMENT = gql`
  ${PICTURE_FRAGMENT}
  ${PICTOGRAM_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${COMPACT_DEPARTMENT_FRAGMENT}
  ${COMPACT_INSTITUTION_FRAGMENT}
  fragment news on News {
    id
    title
    body
    startDate
    endDate
    published
    read
    coverImage {
      ...picture
    }
    pictograms {
      ...pictogram
    }
    pictures {
      ...picture
    }
    videos {
      ...video
    }
    documents {
      ...document
    }
    departments {
      ...compactDepartment
    }
    institution {
      ...compactInstitution
    }
  }
`
