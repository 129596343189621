<script setup lang="ts">
import { useWizardStore } from '@/stores/WizardStore'

interface Props {
  showNames?: boolean
}

withDefaults(defineProps<Props>(), {
  showNames: false,
})

const wizardStore = useWizardStore()

const stepBoxes = Array(wizardStore.steps.length).fill(null)
</script>

<template>
  <div
    class="flex items-center justify-between gap-1 mt-1 desktop:w-contentLarge"
  >
    <div v-for="(step, index) in stepBoxes" :key="index" class="w-full">
      <div class="rounded-16 h-2 bg-neutral200">
        <div
          :class="[
            'w-0 rounded-16 h-2 transition-all duration-500 ease-out bg-gradient-to-l from-darkOcean600 to-darkOcean500',
            wizardStore.currentStep >= index ? 'w-[100%]' : '',
          ]"
        >
          &nbsp;
        </div>
      </div>
      <div
        v-if="showNames"
        :class="[
          'transition duration-300 text-center text-neutral1000 font-semibold text-sm opacity-0 h-0 mt-1',
          wizardStore.currentStep >= index ? 'opacity-100' : '',
        ]"
      >
        <span v-show="wizardStore.currentStep === index">
          <span v-if="wizardStore.steps.length <= 10">
            {{ $t('global.step') }}
          </span>
          {{ index + 1 }}
        </span>
      </div>
    </div>
  </div>
</template>
