class SpinnerEvent extends Event {
  public message: string | null

  constructor(type: string, message: string | null) {
    super(type)
    this.message = message
  }
}

export default SpinnerEvent
