// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const PICTOGRAM_FRAGMENT = gql`
  fragment pictogram on Pictogram {
    id
    description
    source
    type
  }
`
