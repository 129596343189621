import { v4 as uuidv4 } from 'uuid'
import type { IAuth, IOrganization, IPatient } from '@/Interfaces'
import type { LoginSteps, UserTypes } from '@/Types'
import { toUnix } from '@/utils/dates'
import { add } from 'date-fns'
import { throttle } from 'lodash-es'
import { defineStore } from 'pinia'
import { endSpinner, startSpinner } from '@/components/layout/GlobalSpinner.vue'
import { SESSION_MAX_INACTIVITY, SUCCESSFUL_LOGIN_STATES } from '@/Constants'
import LogoutEvent from '@/utils/extends/LogoutEvent'
import SessionManager from '@/utils/session'
import { updateSentryTags } from '@/utils/sentry'

interface IState {
  auth: IAuth
  ready: boolean
}

const DEFAULT_AUTH_DATA: IAuth = {
  userType: null,
  keycloakToken: null,
  token: null,
  tokenExpireAt: null,
  ibgToken: null,
  patient: null,
  proxy: null,
  organization: null,
  deviceId: uuidv4(),
  loginStep: 'none',
  possibleProxyCPR: null,
}

const session = new SessionManager<IAuth>('auth')

const getTokenExpireDate = () => {
  return toUnix(add(new Date(), { minutes: SESSION_MAX_INACTIVITY }))
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      ready: false,
      auth: session.getWithDefault(DEFAULT_AUTH_DATA),
    } as IState
  },
  actions: {
    async init() {
      updateSentryTags()
      session.set(this.auth)
      const updateExpireDate = () => {
        if (this.auth.token) {
          this.auth.tokenExpireAt = getTokenExpireDate()
          session.set(this.auth)
        }
      }

      document.addEventListener('click', updateExpireDate)
      window.addEventListener('keydown', updateExpireDate)
      window.addEventListener('wheel', throttle(updateExpireDate, 1000))
      if (this.auth.userType === 'citizen') {
        await this.fetchApplicationData()
      } else {
        this.ready = true
      }
    },
    async fetchApplicationData() {
      if (this.auth.token && this.auth.patient === null) {
        await Promise.all([this.getUserData()])
      }
      this.ready = true
    },
    async getUserData() {
      if (this.auth.token && this.auth.patient === null) {
        const response = await fetch('patient/self')
        this.auth.patient = await response.json()
      }
    },
    setUserType(userType: UserTypes | null) {
      this.update({ userType })
    },
    setOrganization(organization: IOrganization | null) {
      this.update({ organization })
    },
    async setProxy(proxy: IPatient) {
      this.update({ proxy })
      await this.fetchApplicationData()
    },
    async login(token: string) {
      startSpinner()
      this.update({
        token,
        tokenExpireAt: getTokenExpireDate(),
      })
      if (this.auth.userType === 'citizen') {
        await this.fetchApplicationData()
      }
      endSpinner()
    },
    logout() {
      // Logout actions
      this.auth = DEFAULT_AUTH_DATA
      updateSentryTags()
      sessionStorage.clear()
      localStorage.clear()
      // We need to close all modals etc.
      document.dispatchEvent(new LogoutEvent())
    },
    update(changes: Partial<IAuth>) {
      this.auth = {
        ...this.auth,
        ...changes,
      }
      session.set(this.auth)
      updateSentryTags()
    },
    saveStorage() {
      session.set(this.auth)
    },
    setLoginStep(
      loginStep: LoginSteps,
      possibleProxyCPR: string | null = null
    ) {
      this.$patch({ auth: { loginStep, possibleProxyCPR } })
    },
    setKeycloakToken(value: string | null) {
      this.auth.keycloakToken = value
    },
    isCompleted() {
      return SUCCESSFUL_LOGIN_STATES.includes(this.auth.loginStep)
    },
  },
})
