<script setup lang="ts">
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import { computed, onMounted, ref } from 'vue'
import { toUnix } from '@/utils/dates'
import { useRouter } from 'vue-router'
import { formatDuration } from 'date-fns'
import { da } from 'date-fns/locale'
import { SESSION_INACTIVITY_WARNING } from '@/Constants'
import { useAuthStore } from '@/stores/AuthStore'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'

const router = useRouter()
const authStore = useAuthStore()
const diff = ref<null | number>(null)
type ModalStatus = 'timeout' | 'logout' | 'none'
const status = ref<ModalStatus>('none')

const handleApprove = () => {
  status.value = 'none'
}

const handleLogout = () => {
  authStore.logout()
  router.push('/login')
  status.value = 'none'
}

const calculatedTime = computed(() => {
  if (diff.value === null) {
    return ''
  }

  const formatted = formatDuration(
    {
      minutes: Math.floor(diff.value / 60),
      seconds: diff.value % 60,
    },
    {
      locale: da,
    }
  )

  return `<span class="h500 text-darkOcean600">${formatted}</span>`
})

onMounted(() => {
  setInterval(() => {
    if (authStore.auth.token && authStore.auth.tokenExpireAt !== null) {
      const now = toUnix(new Date())
      diff.value = authStore.auth.tokenExpireAt - now

      if (diff.value <= 0) {
        authStore.logout()
        router.push('/login')
        status.value = 'logout'
      } else if (diff.value < SESSION_INACTIVITY_WARNING) {
        status.value = 'timeout'
      } else {
        status.value = 'none'
      }
    }
  }, 1000)
})

const CONTENTS: Record<ModalStatus, any> = {
  timeout: {
    icon: 'hourglass',
    title: 'onboarding.timeout.title',
    description: 'onboarding.timeout.description',
  },
  logout: {
    icon: 'triangle-exclamation',
    title: 'onboarding.forcedLogOut.title',
    description: 'onboarding.forcedLogOut.description',
  },
  none: undefined,
}

const message = computed(() => {
  return CONTENTS[status.value]
})
</script>

<template>
  <BaseDialog
    :open="status !== 'none'"
    backdrop-type="blur"
    @onClose="$emit('close')"
    v-if="message"
  >
    <div class="w-[calc(100dvw-48px)] p-24 desktop:w-480">
      <div class="flex justify-center mb-20">
        <div
          class="bg-darkOcean100 rounded-full w-60 h-60 flex justify-center items-center"
        >
          <fa-icon :icon="['fas', message.icon]" class="text-2xl" />
        </div>
      </div>
      <div class="text-center h700 mb-12">
        {{ $t(message.title) }}
      </div>
      <div
        class="text-center b300 mb-24"
        v-html="$t(message.description, [calculatedTime])"
      ></div>
      <div v-if="status === 'logout'">
        <ButtonComponent
          type="primary"
          text="global.ok"
          full-width
          gradient
          @click="handleApprove"
        />
      </div>
      <div v-if="status === 'timeout'" class="flex flex-col gap-3">
        <ButtonComponent
          type="primary"
          text="onboarding.timeout.staySignedIn"
          full-width
          gradient
          @click="handleApprove"
        />
        <ButtonComponent
          type="secondary"
          text="global.logout"
          full-width
          gradient
          @click="handleLogout"
        />
      </div>
    </div>
  </BaseDialog>
</template>
