// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { DEPARTMENT_FRAGMENT } from './departments'

export const INSTITUTION_FRAGMENT = gql`
  ${DEPARTMENT_FRAGMENT}
  fragment institution on Institution {
    id
    name
    color
    locale
    departments {
      ...department
    }
    settings {
      planner {
        enabledEmployees
        enabledResidents
      }
      useExternalMedia
      showAllDepartments
    }
  }
`

export const COMPACT_INSTITUTION_FRAGMENT = gql`
  fragment compactInstitution on Institution {
    id
    name
    color
    locale
  }
`
