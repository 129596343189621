import getNewsEntryQuery from '@/graphql/queries/getNewsEntryQuery'
import getNewsQuery from '@/graphql/queries/getNewsQuery'
import type { INewsGQLResponse } from '@/graphql/responses/News'
import apolloClient from '@/utils/apollo'

const getNews = async (dateFrom: string, dateTo: string) => {
  const response = await apolloClient.graphqlClient.query({
    query: getNewsQuery,
    variables: {
      dateFrom,
      dateTo,
      filter: {
        departments: [],
        groups: [],
        institutions: [],
      },
    },
    fetchPolicy: 'no-cache',
  })

  return response.data.newsItem.getNewsByFilters as INewsGQLResponse[]
}

const getNewsDetail = async (id: string) => {
  const response = await apolloClient.graphqlClient.query({
    query: getNewsEntryQuery,
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  })

  return response.data.newsEntry as INewsGQLResponse
}

export default {
  getNews,
  getNewsDetail,
}
