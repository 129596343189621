import activityCancelSignup from '@/graphql/mutations/activityCancelSignup'
import activitySignup from '@/graphql/mutations/activitySignup'
import getActivitiesQuery from '@/graphql/queries/getActivitiesQuery'
import type { IActivityGQLResponse } from '@/graphql/responses/Activity'
import apolloClient from '@/utils/apollo'
import { sentryException } from '@/utils/sentry'

const getActivities = async (dateFrom: string, dateTo: string) => {
  const response = await apolloClient.graphqlClient.query({
    query: getActivitiesQuery,
    variables: {
      dateFrom,
      dateTo,
      filter: {
        departments: [],
        groups: [],
        institutions: [],
      },
    },
    fetchPolicy: 'no-cache',
  })

  return response.data.activity.getActivitiesByFilters as IActivityGQLResponse[]
}

const signup = async (id: number) => {
  const response = await apolloClient.graphqlClient.query({
    query: activitySignup,
    variables: {
      subscription: {
        id,
      },
    },
    fetchPolicy: 'no-cache',
  })

  if (!response.data?.activitySignup?.id) {
    sentryException(new Error(`GraphQLError`), { response })
  }
}

const withdraw = async (id: number) => {
  const response = await apolloClient.graphqlClient.query({
    query: activityCancelSignup,
    variables: {
      subscription: {
        id,
      },
    },
    fetchPolicy: 'no-cache',
  })

  if (!response.data?.activityCancelSignup) {
    sentryException(new Error(`GraphQLError`), { response })
  }
}

export default {
  getActivities,
  signup,
  withdraw,
}
