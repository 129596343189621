// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { PICTURE_FRAGMENT } from './pictures'

export const CLUB_FRAGMENT = gql`
  ${PICTURE_FRAGMENT}
  fragment club on Club {
    id
    logo {
      ...picture
    }
    name
    color
  }
`
