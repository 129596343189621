import SessionManager from './session'
import type { IAuth, IFetchContext } from '@/Interfaces'
import type { Vue } from '@sentry/vue/types/types'
import type { Router } from 'vue-router'
import { VERSION } from '@/Release'

const session = new SessionManager<IAuth>('auth')
let isLoaded = false

const PROD_HOSTNAMES: string[] = [
  'preprod.borgerportalen.dk',
  'borgerportalen.dk',
]

export async function sentryException(err: any, extra: any = {}) {
  // If the Sentyr is not inited, we shouldn't do anything
  if (isLoaded === false) {
    return
  }

  const Sentry = await import('@sentry/vue')
  const auth = session.get()

  const data = {
    ...extra,
  }

  if (auth) {
    data.userType = auth.userType
    data.organizationId = auth.organization?.id
    data.organizationName = auth.organization?.name
  }

  Sentry.withScope((scope) => {
    if (data) {
      for (const key in data) {
        scope.setExtra(key, data[key])
      }
    }

    Sentry.captureException(err)
  })
}

export const toFetchContext = async (response: Response) => {
  // We should clone it because we can't use the same json() function twice.
  const status = response.status
  const contentType = response.headers.get('Content-Type')

  const context: IFetchContext = {
    status,
    contentType,
    body: null,
  }

  try {
    context.body = await response.clone().json()
  } catch (error) {
    context.body = await response.clone().text()
  }

  return {
    HTTPResponse: context,
  }
}

export const updateSentryTags = async () => {
  // If the Sentyr is not inited, we shouldn't do anything
  if (isLoaded === false) {
    return
  }

  const auth: any = session.get() || {}
  const Sentry = await import('@sentry/vue')
  Sentry.setTag('organization', auth?.organization?.name || 'none')
  Sentry.setTag('userType', auth?.userType || 'none')
}

export const sentryInit = async (app: Vue, router: Router) => {
  if (process.env.NODE_ENV === 'production') {
    // Loading the Sentry script dynamically. Because the user should consent
    // for Sentry Analytics cookies.
    const Sentry = await import('@sentry/vue')
    isLoaded = true

    updateSentryTags()

    Sentry.init({
      app,
      dsn: 'https://8f8b877556a75d65d6d0c5dedfbcd8df@o1061588.ingest.sentry.io/4506275125460992',
      release: `borgerportalen-web@${VERSION}`,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            'staging.borgerportalen.dk',
            'review.borgerportalen.dk',
            ...PROD_HOSTNAMES,
            /^\//,
          ],
        }),
        new Sentry.Replay(),
      ],
      tracesSampler: () => {
        // For the production domains
        if (PROD_HOSTNAMES.includes(window.location.hostname)) {
          return 0.2
        }

        // The rest of all
        return 0.1
      },
      attachProps: true,
      attachStacktrace: true,
    })
  }
}
