<script setup lang="ts">
import FetchSpinner from '@/components/shared/FetchSpinner.vue'
import { GlobalEvents } from '@/Enums'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const isFetching = ref(false)
const message = ref<null | string>(null)

const onStarted = (event: any) => {
  message.value = event.message
  isFetching.value = true
}

const onCompleted = (event: any) => {
  message.value = event.message
  isFetching.value = false
}

onMounted(() => {
  document.addEventListener(GlobalEvents.FetchStarted, onStarted)
  document.addEventListener(GlobalEvents.FetchCompleted, onCompleted)
})

onBeforeUnmount(() => {
  document.removeEventListener(GlobalEvents.FetchStarted, onStarted)
  document.removeEventListener(GlobalEvents.FetchCompleted, onCompleted)
})
</script>

<script lang="ts">
import SpinnerEvent from '@/utils/extends/SpinnerEvent'

export const startSpinner = (message: string | null = null) =>
  document.dispatchEvent(new SpinnerEvent(GlobalEvents.FetchStarted, message))

export const endSpinner = (message: string | null = null) =>
  document.dispatchEvent(new SpinnerEvent(GlobalEvents.FetchCompleted, message))
</script>

<template>
  <FetchSpinner :is-fetching="isFetching" :message="message" />
</template>
