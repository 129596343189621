// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const VIDEO_FRAGMENT = gql`
  fragment video on Video {
    id
    provider
    url
    description
    thumbnail
  }
`
