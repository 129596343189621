import type { IAidsDetailResponse } from '@/Interfaces'

const getDetail = async (id: number): Promise<IAidsDetailResponse> => {
  const response = await fetch(`aids/${id}/view`)
  return await response.json()
}

export default {
  getDetail,
}
