// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const DOCUMENT_FRAGMENT = gql`
  fragment document on Document {
    id
    url
    title
    description
  }
`

export const COMPACT_DOCUMENT_FRAGMENT = gql`
  fragment compactDocument on Document {
    id
    url
    title
  }
`
