import { createI18n } from 'vue-i18n'
import { IntlMessageFormat } from 'intl-messageformat'
import type {
  MessageCompiler,
  CompileError,
  MessageContext,
  MessageFunction,
} from 'vue-i18n'
import * as da from '@/js/i18n/da.json'

/**
 * This function convert unsupported translation formats to the supported
 * translation format.
 *
 * @example
 *  // `Hello %s` => `Hello {0}`
 *  $t('Hello %s', ['IBG'])
 * @param translationKey
 * @returns translationKey
 * @link https://webtranslateit.com/docs/translation_interface/validations
 */
export const replacePlaceholders = (translationKey: string) => {
  let index: number = -1
  let changes = 0
  let round = 0

  do {
    index = translationKey.indexOf('%@')
    if (index > -1) {
      translationKey = translationKey.replace('%@', `{${changes}}`)
      changes++
      continue
    }

    index = translationKey.indexOf('%d')
    if (index > -1) {
      translationKey = translationKey.replace('%d', `{${changes}}`)
      changes++
      continue
    }

    index = translationKey.indexOf('%s')
    if (index > -1) {
      translationKey = translationKey.replace('%s', `{${changes}}`)
      changes++
      continue
    }

    round++
  } while (index > -1 && round < 10)

  return translationKey
}

// https://vue-i18n.intlify.dev/guide/advanced/format.html#message-compiler-implementation
export const messageCompiler: MessageCompiler<string, string> = (
  message,
  { locale, key, onError }
): MessageFunction<string> => {
  if (typeof message === 'string') {
    message = replacePlaceholders(message)

    const formatter = new IntlMessageFormat(message, locale)
    return (ctx: MessageContext): string => {
      return formatter.format(ctx.values) as string
    }
  }

  onError && onError(new Error('not support for AST') as CompileError)
  return () => key
}

const messages = {
  da,
}

const i18n = createI18n({
  legacy: false,
  locale: 'da',
  globalInjection: true,
  messageCompiler,
  messages,
})

export default i18n
