import { LOGIN_TOKEN_NOT_FOUND_MAP } from '@/Constants'
import type { ITokenResponse } from '@/Interfaces'
import type { UserTypes } from '@/Types'
import { endSpinner, startSpinner } from '@/components/layout/GlobalSpinner.vue'
import { simpleError } from '@/components/shared/SimpleError.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { sentryException, toFetchContext } from '@/utils/sentry'

export interface IAPITokenResponse {
  data?: ITokenResponse
  redirection: '/' | '/login'
}

const getToken = async (
  authorization: string,
  org: number,
  role: string,
  deviceId: string,
  action: 'none' | 'create' = 'none'
) => {
  const body = {
    org,
    role,
    deviceId,
    create: action === 'create',
  }

  return await window.originalFetch('/api/user/token', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      authorization,
      'Content-Type': 'application/json',
    },
  })
}

const logout = async () => {
  return await fetch('user/logout', { method: 'POST' })
}

const setProxyPatient = async (
  cpr: string,
  action: 'none' | 'create' = 'none'
) => {
  return await fetch('user/setproxypatient', {
    method: 'POST',
    body: JSON.stringify({
      cpr,
      create: action === 'create',
    }),
    isErrorModalDisabled: true,
  })
}

const getProxyPatients = async () => {
  return await fetch('user/getproxypatients')
}

const getAPIToken = async (
  authorization: string,
  create: 'none' | 'create' = 'none'
): Promise<IAPITokenResponse> => {
  const authStore = useAuthStore()

  await authStore.init()

  // Setting the request body
  const body = {
    org: authStore.auth.organization?.id,
    role: authStore.auth.userType,
    deviceId: authStore.auth.deviceId,
    create,
  }

  // It shouldn't be empty but let's assume they are empty somehow
  if (!body.org || !body.role) {
    // We should log the problem
    sentryException(new Error('Missing session data'), { body })

    try {
      // Let's try to get the auth data from session storage
      const authDataAsString = sessionStorage.getItem('auth') || '{}'
      const authDataAsJson = JSON.parse(authDataAsString)

      // Setting the new org and role value
      body.org = authDataAsJson?.organization?.id
      body.role = authDataAsJson?.userType

      // If they are still empty, we should log that. But there is only one way
      // to create this issue. If you go to the MitID login, then close the browser,
      // and open a new tab with the same URL, at this point you would lost the
      // org and the role data. Which means you should start the whole journey.
      if (!body.org || !body.role) {
        sentryException(new Error('Session value is lost!'), {
          authDataAsString,
        })
        // Redirect to the main page
        authStore.logout()
        return { redirection: '/login' }
      }
    } catch (error) {
      // If there is a parsin error we should log that
      sentryException(error, {
        data: sessionStorage.getItem('auth'),
      })
    }
  }

  startSpinner()

  const ROLE_MAP: Record<UserTypes, string> = {
    citizen: 'citizen',
    parent: 'parent',
    relative: 'guardian',
  }

  // Getting the API token by using keycloak access_token
  const response = await getToken(
    authorization,
    authStore.auth.organization?.id || 0,
    ROLE_MAP[authStore.auth.userType || 'citizen'],
    authStore.auth.deviceId,
    create
  )
  endSpinner()

  // If the user is not found on, we should set the next step
  if (response.status === 404) {
    authStore.setLoginStep(
      LOGIN_TOKEN_NOT_FOUND_MAP[authStore.auth.userType || 'citizen']
    )
    return { redirection: '/' }
  }

  // It is an inactive user. https://proreact.atlassian.net/browse/KMD-1025
  if (response.status === 401) {
    authStore.setLoginStep('inactive')
    return { redirection: '/login' }
  }

  // Everything is normal.
  if (response.status === 200) {
    return {
      data: await response.json(),
      redirection: '/',
    }
  }

  authStore.logout()
  sentryException(new Error('Login error'), await toFetchContext(response))
  simpleError({ message: 'global.anErrorOccurred', shouldBeTranslated: true })
  return { redirection: '/login' }
}

const getIBGToken = async () => {
  const response = await fetch('ibg/token')
  return response.json()
}

export default {
  getToken,
  getAPIToken,
  logout,
  setProxyPatient,
  getProxyPatients,
  getIBGToken,
}
