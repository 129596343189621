<script setup lang="ts">
import { ref } from 'vue'
import { useWizardStore, type IPromptOnCloseData } from '@/stores/WizardStore'
import { useGeneralStore } from '@/stores/GeneralStore'
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import StepIndicator from './indicator/StepIndicator.vue'
import MobileStepTitle from './indicator/MobileStepTitle.vue'
import LogoComponent from './LogoComponent.vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const wizardStore = useWizardStore()
const generalStore = useGeneralStore()

const showModal = ref<IPromptOnCloseData>({
  status: false,
  titleKey: 'courses.exitWithoutBookingTitle',
  descriptionKey: 'courses.exitWithoutBookingDescription',
  onCloseFunction: () => {
    throw new Error('Wizard close function should be set')
  },
})

const closeWizard = () => {
  wizardStore.disableWarningPrompt()
  wizardStore.stop()
}

const closeWizardQuestion = () => {
  if (wizardStore.promptOnClose.status) {
    showModal.value = {
      status: wizardStore.promptOnClose.status,
      titleKey: wizardStore.promptOnClose.titleKey,
      descriptionKey: wizardStore.promptOnClose.descriptionKey,
      onCloseFunction: closeWizard,
    }
  } else {
    closeWizard()
  }
}

const goToDashboard = () => {
  closeWizard()
  router.push('/')
}

const handleLogoClick = () => {
  if (wizardStore.promptOnClose.status) {
    showModal.value = {
      status: wizardStore.promptOnClose.status,
      titleKey: wizardStore.promptOnClose.titleKey,
      descriptionKey: wizardStore.promptOnClose.descriptionKey,
      onCloseFunction: goToDashboard,
    }
    return
  } else {
    goToDashboard()
  }
}
</script>

<template>
  <div
    :class="[
      'w-full bg-neutral50 py-16 px-16 fixed top-0 z-40 shadow-smallBottom',
      generalStore.animatedTitle ? 'shadow-sm' : '',
    ]"
  >
    <div :class="['flex justify-between']">
      <div
        class="w-0 invisible flex justify-start items-center desktop:visible desktop:w-1/4"
      >
        <LogoComponent @click="handleLogoClick" />
      </div>
      <div
        v-if="
          wizardStore.showStepIndicator && generalStore.breakpoint === 'desktop'
        "
        class="w-[calc(100%-40px)] flex items-center justify-center"
      >
        <StepIndicator show-names />
      </div>
      <div
        class="w-full flex flex-col justify-center items-end desktop:w-1/4 desktop:justify-end"
      >
        <div class="flex items-center cursor-pointer w-full desktop:w-full">
          <div
            v-if="generalStore.breakpoint === 'desktop'"
            class="flex justify-end w-full"
          >
            <ButtonComponent
              type="secondary"
              icon="xmark"
              text="global.close"
              size="small"
              @click="closeWizardQuestion()"
            />
          </div>
          <div v-if="generalStore.breakpoint !== 'desktop'" class="w-52">
            <ButtonComponent
              type="secondary"
              size="large"
              icon="xmark"
              class="mr-12 transition-all duration-300"
              @click="closeWizardQuestion()"
            />
          </div>
        </div>
      </div>
      <div
        v-if="
          wizardStore.showStepIndicator && generalStore.breakpoint === 'mobile'
        "
      >
        <MobileStepTitle />
      </div>

      <slot name="left" />
    </div>

    <div
      v-if="
        wizardStore.showStepIndicator && generalStore.breakpoint === 'mobile'
      "
      class="mt-3"
    >
      <StepIndicator :show-names="false" />
    </div>
  </div>

  <ConfirmDialog
    :show="showModal.status"
    :title-key="showModal.titleKey"
    :description-key="showModal.descriptionKey"
    @confirm="showModal.onCloseFunction"
    @cancel="showModal.status = false"
  />
</template>
