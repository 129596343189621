import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBooks,
  faWheelchair,
  faCalendar,
  faEnvelopeOpenText,
  faMedal,
  faPersonRunning,
  faFileSignature,
  faMegaphone,
  faBorderAll,
  faUser,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faCircle,
  faCircleInfo,
  faHourglass,
  faTriangleExclamation,
  faListCheck,
  faXmark,
  faHouse,
  faPhone,
  faIdCard,
  faUsers,
  faStethoscope,
  faFamilyPants,
  faFileCircleCheck,
  faQuestion,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons'

// SOLID

library.add(
  faBooks,
  faWheelchair,
  faCalendar,
  faEnvelopeOpenText,
  faMedal,
  faPersonRunning,
  faFileSignature,
  faMegaphone,
  faBorderAll,
  faUser,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faCircle,
  faCircleInfo,
  faHourglass,
  faTriangleExclamation,
  faListCheck,
  faXmark,
  faHouse,
  faPhone,
  faIdCard,
  faUsers,
  faStethoscope,
  faFamilyPants,
  faFileCircleCheck,
  faQuestion,
  faUserPlus
)
