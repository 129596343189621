// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

const COMPACT_PROFILE_FIELDS = `
  id
  type
  displayName
  displayImage
  showImage
`

export const COMPACT_EMPLOYEE_FRAGMENT = gql`
  fragment compactEmployee on Employee {
    ${COMPACT_PROFILE_FIELDS}
    phone
    title
  }
`

export const COMPACT_RESIDENT_FRAGMENT = gql`
  fragment compactResident on Resident {
    ${COMPACT_PROFILE_FIELDS}
  }
`

export const RELATIVE_FRAGMENT = gql`
  fragment relative on Relative {
    ${COMPACT_PROFILE_FIELDS}
    email
    mobile
  }
`

export const COMPACT_PROFILE_FRAGMENT = gql`
  ${COMPACT_EMPLOYEE_FRAGMENT}
  ${COMPACT_RESIDENT_FRAGMENT}
  ${RELATIVE_FRAGMENT}
  fragment compactProfile on Profile {
    ...compactEmployee
    ...compactResident
    ...relative
  }
`
