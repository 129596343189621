<script setup lang="ts">
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import { useWizardStore } from '@/stores/WizardStore'
import WizardHeaderComponent from '@/components/header/WizardHeaderComponent.vue'
import BaseWizard from '@/wizards/BaseWizard.vue'

const wizardStore = useWizardStore()
</script>

<template>
  <BaseDialog v-if="wizardStore.show" :open="true">
    <div class="fixed top-0 left-0 w-screen h-screen bg-neutral50">
      <WizardHeaderComponent />
      <BaseWizard />
    </div>
  </BaseDialog>
</template>
