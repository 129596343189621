// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const DEPARTMENT_FRAGMENT = gql`
  fragment department on Department {
    id
    institutionId
    name
    color
    screenOnly
    settings {
      meal {
        daysForward
        signup
      }
      shift {
        dayDisplay
        daysForward
        showGraphics: gfx
        showCancelled: showCancelledFp
      }
      appConfig {
        modules {
          id
          type
        }
      }
      simpleSchedulePlan {
        startTime: starttime
        endTime: endtime
        name
        icon
      }
    }
  }
`

export const COMPACT_DEPARTMENT_FRAGMENT = gql`
  fragment compactDepartment on Department {
    id
    institutionId
    name
    color
    screenOnly
  }
`
