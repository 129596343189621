import { type IErrorMessage } from '@/Interfaces'

class ErrorEvent extends Event {
  public message: IErrorMessage

  constructor(type: string, message: IErrorMessage) {
    super(type)
    this.message = message
  }
}

export default ErrorEvent
