import activities from './activities'
import aids from './aids'
import news from './news'
import user from './user'

export default {
  activities,
  aids,
  news,
  user,
}
